//
// Main CSS
//

main {

  padding: 10px 0 0 0;
  min-height: 300px;
  @include clearfix;

  .main-inner,
  .ce-inner {
    max-width: $maxPageWidth;
    margin: auto;
    padding: 0 20px;
  }

  > section {
    max-width: $maxPageWidth;
    margin: 0 auto 80px auto;

    &.full-width {
      max-width: none;
    }

    &.content-main {
      ul {
        margin-top: 0;
        li {
          margin-top: 8px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }


    @media screen and (max-width: $size-XXXL) {
      padding-left: 20px;
      padding-right: 20px;
    }

  }

}


.bg-grey {
  background: $grey-light;
}

.btn-primary {
  border: 2px solid $blue;
  color: $blue;
  display: inline-block;
  font-size: 1.125rem;
  padding: 6px 10px;
  text-transform: uppercase;

  &:hover {
    background: $blue;
    color: $white;
  }
}

// on Jobs page
.page-id-4 {
  .ce-bodytext p {
    &:last-child {
      text-align: center;
      padding: 20px 0 40px 0;
    }

    .btn-primary {
      margin-bottom: 10px;
    }
  }
}

.content-inner > h2,
.content-inner > h3 {
  text-align: center;
  margin: 10px 0 20px 0;
}