//
// References with Slider CSS
//

.references {
  margin-top: 0;
  background: $grey-light;
  padding: 50px 0;

  .inner {
    margin: auto;
    max-width: $maxPageWidth;
    padding: 0 20px;
  }

  .row {
    @include clearfix;
  }

  .reference-logos {
    float: left;
    width: 20%;
    padding-right: 80px;
  }

  .reference-logo {
    text-align: center;

    .logo-kununu {
      margin-bottom: 15px;
    }

    img {
      display: inline-block;
    }
  }
}

.reference-slider {
  float: left;
  width: 80%;
  margin-bottom: 0;

  .slick-dots {
    position: relative;
    text-align: left;
    padding-left: 32px;
    margin-bottom: 0;

    li {
      width: 10px;
    }
  }
}

.ce-reference {

  padding: 0 40px;

  .ce-reference-quote {
    @include font-size(20);
    font-style: italic;
    color: $blue;
    position: relative;

    br {
      display: none;
    }

    p {
      display: inline;
    }

    &::before {
      content: "\"";
      top: 0;
    }
    &::after {
      content: "\"";
    }
  }

  .ce-reference-author {
    padding-top: 20px;
    font-style: italic;
    color: $blue;
  }

}


@media screen and (max-width: $size-XL) {
  .ce-reference .ce-reference-quote {
    @include font-size(18);
  }
}

@media screen and (max-width: $size-L) {
  .references {
    .reference-logos {
      float: none;
      width: auto;
      padding: 0;
      overflow: hidden;

      .reference-logo {
        float: left;
        width: 50%;
        text-align: center;
        margin-bottom: 20px;
        padding: 0 20px;

        .logo-kununu {
          margin-bottom: 0;
        }

        .logo-aps-co {
          padding-top: 15px;
        }

        img {
          max-width: 140px;
          width: 100%;
        }
      }
    }
  }
  .reference-slider {
    float: none;
    width: auto;
  }
}

@media screen and (max-width: $size-M) {
  .ce-reference .ce-reference-quote {
    @include font-size(17);
  }
}

@media screen and (max-width: $size-S3) {
  .ce-reference .ce-reference-quote {
    @include font-size(13);
  }
}