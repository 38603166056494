//
// Grid CSS
//

.row {
  @include clearfix;

  .col-20 {
    float: left;
    width: 20%;
  }

  .col-25 {
    float: left;
    width: 25%;
  }

  .col-33 {
    float: left;
    width: 33.3333333%;
    text-align: center;
  }

  .col-50 {
    float: left;
    width: 50%;
  }

  &.grid-3 {
    margin: 0 -30px;
  }

  &.with-gutter {

    .col-33 {
      padding: 0 30px;
    }

    .col-50 {
      &:nth-child(odd) {
        padding-right: 30px;
      }
      &:nth-child(even) {
        padding-left: 30px;
      }
    }

  }







  @media screen and (max-width: $size-M) {
    .col-50 {
      float: none;
      width: auto;
      margin-top: 1em;
    }

    &.with-gutter {
      .col-50 {
        &:nth-child(odd) {
          padding: 0;
        }
        &:nth-child(even) {
          padding: 0;
        }
      }

    }

  }


  @media screen and (max-width: $size-M2) {
    .col-33 {
      float: none;
      width: auto;
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}


.full-width {
  /*margin-left: -3000px;
  margin-right: -3000px;*/
}

.content-inner {
  margin: auto;
  max-width: $maxPageWidth;
  padding: 30px 0 40px 0;
}


// UL List 50/50
ul.cols-50-50 {
  margin: 0 0 1em 0;
  padding: 0;
  list-style: none;
  @include clearfix();

  li {
    float: left;
    width: 50%;
    padding: 0 0 0 30px;

    &:nth-child(odd) {
      clear: left;
      padding: 0 30px 0 0;
    }
  }

}