//
// Career at Abalon CSS
//


.text-highlight{
  @include font-size(30);
  text-transform: uppercase;
  color: $blue;
  line-height: 1.2;
}

.ce-textsimple {

  ul {
    @include ul-reset;

    li {
      position: relative;
      padding-left: 15px;
      margin-left: 18px;

      &::after {
        content: "-";
        position: absolute;
        top: -1px;
        left: -15px;
        font-weight: 700;
      }
    }
  }

}


.career-chances {
  border-top: 2px solid $grey;
  padding-top: 30px;

  h2 {
    text-align: center;
    margin-bottom: 70px;
  }

  .career-chances-tabbed-content {

    &:nth-child(2) {
      display: block;
    }

    margin-left: 480px;

    h3 {
      margin-top: 0;
    }

    ul {
      @include ul-reset;

      li {
        position: relative;
        padding-left: 15px;
        margin-left: 18px;

        &::after {
          content: "-";
          position: absolute;
          top: 0;
          left: -15px;
        }
      }
    }

    .carrer-chances-intro {
      margin-bottom: 25px;
    }

    .career-chances-tabbed-content-item {
      display: none;
    }

    .career-chances-action {
      overflow: hidden;
      padding-top: 50px;

      a {
        display: block;
        float: left;
        margin-right: 20px;
        margin-bottom: 15px;
        color: $blue;
        text-transform: uppercase;
        border: 2px solid $blue;
        text-align: center;
        font-weight: 600;
        line-height: 30px;
        padding: 0 15px;

        &:hover {
          background: $blue;
          color: $white;
        }
      }
    }

  }

}

.career-chances-tabbed-area {
  overflow: hidden;
}

.career-chances-tabs {
  @include ul-reset;
  float: left;
  width: 360px;

  button {
    text-align: center;
    background: $white;
    border: 3px solid $blue;
    color: $blue;
    @include font-size(24);
    font-weight: 600;
    width: 320px;
    margin-bottom: 30px;
    line-height: 40px;

    &:hover,
    &.active {
      background: $blue;
      border: 3px solid $blue;
      color: $white;
    }
  }

}


@media screen and (max-width: $size-L4) {
  .career-chances .career-chances-tabbed-content {
    margin-left: 360px;
  }
}


@media screen and (max-width: $size-L2) {

  .career-chances .career-chances-tabbed-content {
    margin-left: 290px;
  }
  .career-chances-tabs {
    width: 260px;
    button {
      width: 100%;
      @include font-size( 20);
    }
  }
}


@media screen and (max-width: $size-M) {
  .career-chances .career-chances-tabbed-content {
    margin: 0;
  }

  .career-chances-tabs {
    float: none;
    width: auto;
    button {
      margin-bottom: 20px;
    }
  }
}


@media screen and (max-width: $size-XS) {
  .career-chances-tabbed-area button {
    width: 280px;
    @include font-size(20);
  }
}