//
// BLOG CSS
//

.page.abalon-blog > header {
  &::after {
    content: "";
    display: block;
    background: $blue;
    height: 5px;
  }
}

.abalon-blog {

  background: #EFEFEF;
  /*
  background: repeating-linear-gradient(
                  -45deg,
                  #cfcfcf 0,
                  #cfcfcf 1px,
                  $grey-light2 1px,
                  $grey-light2 5px
  );*/

  background: repeating-linear-gradient(135deg,
          $grey-light 0,
          #dfdfdf 0px /* transition from previous blue stripe */, #dfdfdf 1px,
          $grey-light calc(.05em + 1px) /* from black to blue */, $grey-light .5em
  );

  .page > header {

    &::after {
      content: "";
      display: block;
      background: $blue;
      height: 5px;
    }

  }

  .main-inner {
    @include clearfix;
    padding-top: 50px;

  }

  .content-main {
    float: left;
    padding-right: 20px;
    width: 70%;

    .news-list-date {
      padding: 15px 0 0 0;
    }

    .more-link-btn {
      display: block;
      margin: 20px auto 0 auto;
      width: 142px;
      padding: 3px 10px;
      color: $white;
      background: $blue;
    }

  }

  .news-list-item {
    padding: 20px;
    background: $white;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    img {
      display: block;
    }
  }

  .news-list-date {
    display: block;
    @include font-size(18);
  }

  h1,
  h2 {
    margin: 6px 0;
    text-transform: none;
    line-height: 1.1;
  }


  aside {
    float: left;
    width: 30%;

    h2 {

      &::after {
        content: "";
        display: block;
        background: $blue;
        height: 5px;
        margin-top: 20px;
      }
    }

    .ce-plugin,
    > div {
      padding: 20px;
      background: $white;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    ul {
      @include ul-reset;
      padding-top: 5px;

      li {
        margin-top: 15px;
      }
    }

    h4 {
      margin: 0;
      font-weight: 400;
    }

    .openjobs-latest {
      padding-bottom: 15px;

    }

    .news-list {

      ul {
        padding-top: 15px;

        li {
          border-top: 1px solid $grey-mid;
          margin-top: 8px;
          padding-top: 8px;

          &:first-child {
            border-top: none;
            margin: 0;
            padding: 0;
          }
        }

      }

    }

    .more-link-btn {
      display: inline-block;
      margin-top: 30px;
      padding: 3px 10px;
      color: $white;
      background: $blue;
    }

  }


  // detail
  .news-detail {
    background: $white;
    padding: 20px;

    h2,
    h1 {
      margin-bottom: 20px;
      text-align: left;
    }

    footer {
      margin-top: 50px;
      padding-top: 5px;
      border-top: 1px solid $grey-mid;
      @include clearfix;
    }

    .social {
      float: right;
      margin-top: 6px;

      .like {
        height: 22px;
        width: 88px;
        overflow: hidden;
      }

      .share {
        margin-bottom: 5px;
      }
    }
  }


  @media screen and (max-width: $size-L2) {

    .main-inner {
      padding-left: 20px;
      padding-right: 20px;
    }

    .content-main {
      float: none;
      width: auto;
      padding-right: 0;
    }

    aside {
      float: none;
      width: auto;

      .ce-plugin,
      > div {
        margin-top: 20px;
        &:first-child {
          margin-top: 20px;
        }
      }
    }

  }


  @media screen and (max-width: $size-XXS) {
    .main-inner {
      padding-left: 0;
      padding-right: 0;
    }
  }


}

.news-list-view {

  .page-navigation {
    margin-top: 15px;
  }

  .f3-widget-paginator {
    @include ul-reset;
    display: flex;

    li {
      margin: 0 5px;
    }
  }
}