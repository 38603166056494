//
// Nav Main CSS
//

.nav-main {

  float: right;
  margin-top: 20px;

  transition: all 0.3s;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @include clearfix;

    li {
      float: left;
      margin-left: 15px;
      position: relative;

      a {
        color: $grey-dark;
        padding-bottom: 5px;

        &:hover {
          color: $blue;
        }
        &.active {
          color: $blue;
          font-weight: 600;
        }
      }

      // level 2
      ul {
        display: none;
        position: absolute;
        left: -20px;
        top: 28px;
        width: 180px;
        background: $grey-mid;
        padding: 5px 20px;

        li {
          float: none;
          @include font-size(14);
          border-top: 1px solid $blue;
          margin: 0;
          a {
            display: block;
            padding: 10px 0;
          }
          &:first-child {
            border-top: none;
          }
        }
      }

      &:hover ul {
        display: block;
      }
    }
  }

}

.smaller .nav-main {
  margin-top: 5px;

  ul li ul {
    //top: 23px;
  }
}

// mobile
@media screen and (max-width: $size-XL) {

  .nav-main {
    position: absolute;
    right: -280px;
    top:  85px;
    width: 280px;
    //display: none;
    background: $grey-mid;
    padding: 15px 20px;

    ul {

      li {
        float: none;
        margin: 0;

        a {
          color: $blue;
          line-height: 40px;
          @include font-size(22);
        }

        // level 2
        ul {
          position: relative;
          right: auto;
          top: auto;
          display: block;
          padding: 0 0 0 25px;

          li {
            float: none;

            a {
              @include font-size(16);
              line-height: 30px;
            }
          }

        }
      }

    }
  }

  .smaller {
    .nav-main {
      top: 62px;
    }
  }

}

@media screen and (max-width: $size-M) {
  .nav-main {
    top: 46px;
  }

}


@media screen and (min-width: $size-XL) {

  .nav-main {
    display: block !important;
  }

}
