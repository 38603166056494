//
// Social Media CSS
//


.social-media {

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;

    li {
      float: left;
      margin-left: 15px;

      a {
        display: block;
        overflow: hidden;
        color: $grey-dark;
      }
    }
  }

}

// header
header .social-media {

  float: left;
  //margin-top: 20px;

  ul {

    padding-left: 15px;

    li {
      margin-left: 0;
    }

  }

  a {
    text-indent: -99999px;
    width: 25px;
    height: 25px;
  }

  .xing {
    background: url('../Svgs/icon_xing_blue.svg') no-repeat;
  }
  .facebook {
    background: url('../Svgs/icon_fb_blue.svg') no-repeat;
  }
  .linkedin {
    background: url('../Svgs/icon_linked_blue.svg') no-repeat;
    background-size: 28px;
    background-position: center 0;
  }
  .kununu {
    background: url('../Svgs/icon_kununu_blue.svg') no-repeat;
    background-size: 15px;
    background-position: center 5px;
  }
  .instagram {
    background: url('../Svgs/icon_instagram_blue.svg') no-repeat;
    background-size: 20px;
    background-position: center 4px;
  }
  .twitter {
    background: url('../Svgs/icon_twitter_blue.svg') no-repeat;
    background-size: 25px;
    background-position: 0;
  }
  .bestcruiter {
    background: url('../Svgs/icon_bestcruiter_blue.svg') no-repeat;
    background-size: 20px;
    background-position: 0;
  }

  @media screen and (max-width: $size-S5) {
    float: none;
    clear: both;
    text-align: center;

    ul {
      padding: 5px 0 0 0;
      display: inline-block;
    }
  }

  @media screen and (max-width: $size-S3) {
    //display: none;
  }

}

// footer
footer .social-media {

  float: right;

  a {
    text-indent: -99999px;
    width: 35px;
    height: 35px;
  }

  .xing {
    background: url('../Svgs/icon_xing_white.svg') no-repeat;
  }
  .facebook {
    background: url('../Svgs/icon_fb_white.svg') no-repeat;
  }
  .linkedin {
    background: url('../Svgs/icon_linked_white.svg') no-repeat;
  }
  .kununu {
    background: url('../Svgs/icon_kununu_white.svg') no-repeat;
  }
  .instagram {
    background: url('../Svgs/icon_instagram_white.svg') no-repeat;
  }
  .twitter {
    background: url('../Svgs/icon_twitter2_white.svg') no-repeat;
  }
  .bestcruiter {
    background: url('../Svgs/icon_bestcruiter2_white.svg') no-repeat;
  }


  @media screen and (max-width: $size-L4) {
    float: none;
    padding-top: 10px;

    ul {
      text-align: center;
      li {
        display: inline-block;
        float: none;
      }
    }
  }

}