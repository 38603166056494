//
// Open Jobs EXT
//

// job list h1 left align
.page-id-4 h1 {
  text-align: left;
}


// job search
.job-search {
  margin: 0 calc(-50vw + 50%);
  background: url("../Images/bg_action_area2.jpg") no-repeat top center;
  background-size: 100%;
  color: $white;

  @include clearfix;

  fieldset {
    border: none;
    margin: 0;
  }

  .filter-areas {
    float: left;
    width: 40%;
    position: relative;
    margin: 30px 0;
    padding: 10px 0;
  }

  .filter-area-title {
    border: 2px solid $white;
    text-transform: uppercase;
    padding: 2px 10px;
    position: relative;
    width: 100%;
    max-width: 300px;

    &::after {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      //@include triangle('down', 10px, #fff);
      background: url("../Svgs/icon_abalon_white.svg") no-repeat;
      background-size: 20px;
      right: 10px;
      top: 4px;
      transform: rotate(180deg);
    }

    &.active {
      &::after {
        transform: rotate(90deg);
      }
    }
  }

  .filter-areas {
    &.wide-area-title {
      //float: none;
      width: auto;
      .filter-area-title {
        display: inline-block;
        max-width: none;
        width: auto;
        padding-right: 40px;
      }

    }
  }

  .filter-options {
    position: absolute;
    left: 2px;
    top: 50px;
    background: $blue;
    padding: 15px 0;
    display: none;
    z-index: 9;
  }

  .filter-area {
    position: relative;
    overflow: hidden;
    //text-transform: uppercase;

    // move out of visible area
    input {
      position: absolute;
      top: 7px;
      left: -20px;
    }

    label {
      display: block;
      padding: 4px 12px;
      cursor: pointer;
      &:hover {
        background: #0a429c;
      }
    }
  }


  .filter-categories {
    padding: 20px 0;
    overflow: hidden;

    &.reduced-height {
      padding: 38px 0;
    }
  }

  .filter-category {
    display: none;
    position: relative;
    overflow: hidden;
    float: left;
    width: 25%;
    padding: 5px 0;

    label {
      display: block;
      cursor: pointer;
      position: relative;
      padding-left: 30px;

      &::before {
        position: absolute;
        left: 0;
        top: 3px;
        content: "";
        width: 18px;
        height: 18px;
        border: 1px solid;
        border-radius: 50%;
        line-height: 0.3;
        text-align: center;
      }
    }

    // move out of visible area
    input {
      position: absolute;
      left: -20px;
      top: 7px;

      &:checked {
        + label {
          &::before {
            background: $white;
            padding: 1px;
            background-clip: content-box;
          }
        }
      }
    }

  }


  @media screen and (max-width: $size-L2) {

    padding: 0 10px;

    .filter-options {
      top: 70px;
    }

    .filter-areas {
      float: none;
      width: auto;
      margin: 0;
      padding: 30px 0 0 0;
    }

    .filter-categories {
      &.reduced-height {
        padding: 20px 0;
      }
    }

  }

  @media screen and (max-width: $size-S4) {

    .filter-category {
      width: 50%;
    }

  }

}


// list
.open-jobs {

  max-width: $maxPageWidth;
  margin: auto;
  background: $grey-light;

  .open-jobs-active-filters {

    display: none;

    border-top: 1px solid $grey-mid;
    padding: 10px 20px;
    @include font-size(14);

    .reset {
      float: right;
      border: none;
      background: transparent;
    }
  }

  .open-jobs-list {

    margin-top: 30px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid $grey-mid;

      li {

        margin: 0;
        padding: 4px 0;
        border-top: 1px solid $grey-mid;
        &:nth-child(odd) {
          background: #fbfbfb;
        }

        a {
          display: block;
          padding: 5px 0;
          overflow: hidden;
        }

      }

    }


    .open-jobs-title {
      float: left;
      width: 40%;
      padding-right: 20px;
      font-weight: 600;

      h4 {
        margin: 0;
        padding-left: 20px;
        font-weight: 600;
      }
      .company {
        padding-left: 20px;
        color: $grey-dark;
      }
    }

    .open-jobs-location {
      float: left;
      width: 18%;
      padding-right: 20px;
      font-weight: 600;
    }

    .open-jobs-salary {
      float: left;
      width: 18%;
      padding-right: 20px;
      font-weight: 600;
    }

    .open-jobs-category {
      float: left;
      width: 24%;
      padding-right: 20px;
      font-weight: 600;

    }

    .open-jobs-show-all {
      text-align: center;
      background: $white;
      padding-top: 40px;
    }

    .btn-show-all {
      border: 2px solid $blue;
      color: $blue;
      display: inline-block;
      font-size: 1.125rem;
      padding: 6px 10px;
      text-transform: uppercase;

      &:hover {
        background: $blue;
        color: $white;
      }
    }

    .open-jobs-list-headers {
      overflow: hidden;
      padding: 4px 0;

      .open-jobs-title {
        padding-left: 20px;
      }
    }


    @media screen and (max-width: $size-M2) {

      .open-jobs-title {
        width: 50%;
      }

      .open-jobs-location {
        width: 50%;
      }

      .open-jobs-salary {
        clear: left;
        width: 50%;
        padding-right: 0;
        padding-left: 20px;
      }

      .open-jobs-category {
        width: 50%;
      }

    }

    @media screen and (max-width: $size-S4) {

      .open-jobs-title {
        float: none;
        width: auto;
      }

      .open-jobs-location {
        float: none;
        width: auto;
        padding-left: 20px;
      }

      .open-jobs-salary {
        float: none;
        width: auto;
        padding-right: 0;
        padding-left: 20px;
      }

      .open-jobs-category {
        float: none;
        width: auto;
        padding-left: 20px;
      }

    }

  }

}


// Detail

.openjobs-detail {
  padding-top: 10px;

  h1 {
    text-align: left;
    margin-bottom: 20px;

    span {
      text-transform: none;
    }
  }

  h4 {
    @include font-size(22);
    margin: 0 0 10px 0;
    font-weight: 400;
    color: $blue;
  }

  .job-short-description,
  .job-description,
  .job-requirements,
  .job-benefits,
  .job-final-text,
  .job-title,
  .job-right-fit {
    margin-bottom: 40px;
  }

  .job-final-text {
    padding-top: 30px;
  }

  .job-contact-name {
    @include font-size(24);
    font-weight: 600;
    margin: 15px 0 20px 0;
  }

  .clearer {
    clear: both;
  }

  .job-legal-note {
    clear: both;
    @include font-size(14);
  }

  .back-link {
    border-top: 1px solid $grey;
    margin-top: 100px;
    padding: 20px 0 50px 0;

    a {
      display: inline-block;
      background: $blue;
      color: $white;
      padding: 3px 8px;
    }
  }

  .job-core-facts {
    margin-bottom: 20px;

    ul {
      @include ul-reset;
    }

    li {
      margin-top: 2px;

      .label {
        font-weight: 600;
      }
    }
  }


}

.job-detail-main {
  float: left;
  width: 70%;
  padding-right: 100px;

  h4 {
    border-bottom: 1px solid $grey;
  }

  ul {
    padding-left: 20px;

    li {
    }

  }

  .job-core-facts {
    display: none;

    @media screen and (max-width: $size-L4) {
      display: block;
    }
  }

  .video-area {
    margin-bottom: 40px;
  }

}


.job-detail-sidebar {
  float: left;
  width: 30%;
  padding-left: 30px;

  .job-core-facts {
    margin-bottom: 30px;

    @media screen and (max-width: $size-L4) {
      display: none;
    }
  }

  .job-contact {
    margin-bottom: 40px;
  }

  h2 {
    margin-top: 0;
    line-height: 1;
  }

  .job-apply-btn {
    a {
      display: inline-block;
      background: $blue;
      color: $white;
      padding: 3px 8px;
    }
  }

  @media screen and (max-width: $size-XXXL) {
    padding-left: 0;
  }

}


@media screen and (max-width: $size-L4) {

  .job-detail-main {
    float: none;
    width: auto;
    padding: 0;
  }

  .job-detail-sidebar {
    float: none;
    width: auto;
    padding: 0;
    margin-top: 50px;
  }

}