//
// button-grid CSS
//

.button-grid {

  //height: 265px;
  background: url("../Images/bg_action_area2.jpg") repeat-y top center;
  background-size: 100%;
  text-align: center;
  color: $white;
  //margin-top: 80px;
  padding-top: 0px;

  h3 {
    @include font-size(30);
    font-weight: 300;
    margin-bottom: 25px;
    color: $white;
  }

  h2 {
    color: $white;
    @include font-size(20);
    border: 1px solid $white;
    width: 360px;
    margin: 0;
    padding: 10px 0;
    //cursor: pointer;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;
    width: 33.3333333%;
    overflow: hidden;
    margin-bottom: 20px;

    &:nth-child(4),
    &:nth-child(7) {
      clear: left;
    }
    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(9) {
      h2 {
        float: right;
      }
    }
    &:nth-child(2),
    &:nth-child(5),
    &:nth-child(8) {
      h2 {
        margin: auto;
      }
    }
  }

  .accordion-text {
    display: none;
  }


  @media screen and (max-width: $size-XL) {
    h2 {
      @include font-size(16);
      border: 1px solid $white;
      width: 97%;
    }
  }

  @media screen and (max-width: $size-L) {
    h2 {
      @include font-size(14);
    }
  }

  @media screen and (max-width: $size-M2) {
    li {
      width: 50%;
      &:nth-child(odd) {
        clear: left;

        h2 {
          margin-left: 0;
          margin-right: 0;
          float: none;
        }
      }
      &:nth-child(even) {
        clear: none;

        h2 {
          margin-left: 0;
          margin-right: 0;
          float: none;
        }
      }
    }
  }

  @media screen and (max-width: $size-S4) {
    li {
      float: none;
      width: auto;
      h2 {
        width: auto;
      }
    }
  }

}