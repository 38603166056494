//
// Testimonials Slider CSS
//

.ce-slider {

  margin-bottom: 50px;

  header {
    background: $blue;
    padding: 0 25px;
  }

  h3 {
    color: $white;
    max-width: $maxPageWidth;
    margin: auto;
    @include font-size(30);
    padding: 25px 0;
  }

  .ce-slider-wrap {
    background: url("../Images/bg_testimonials.jpg") no-repeat center bottom;
    background-size: 100%;
    min-height: 270px;
  }

  .ce-slider-inner {
    margin: auto;
    max-width: 1060px;
    padding: 30px 0 50px 0;
  }

  .ce-slider-slide-item {
    overflow: hidden;
    margin: 0 30px;
  }

  .image-container {
    float: left;
    width: 30%;
    padding-right: 60px;

    img {
      border: 3px solid $white;
    }
  }

  .ce-slider-caption {
    float: left;
    width: 70%;
    padding-left: 20px;
    color: $white;
    @include font-size(22);
    font-style: italic;
  }

  .ce-slider-caption-text {
    margin-bottom: 15px;
  }


  @media screen and (max-width: $size-XL) {

    .image-container {
      padding-right: 30px;
    }

    .ce-slider-caption {
      padding-left: 0;
      @include font-size(19);
    }

  }


  @media screen and (max-width: $size-M) {
    .ce-slider-caption {
      @include font-size(17);
      width: 75%;
    }
    .image-container {
      padding-right: 20px;
      width: 25%;
    }
  }


  @media screen and (max-width: $size-S3) {
    .ce-slider-inner {
      padding: 20px 0 20px 0;
    }
    .ce-slider-slide-item {
      margin: 0 20px;
    }
    .ce-slider-caption {
      @include font-size(13);
      width: 100%;
      float: none;
    }
    .image-container {
      float: none;
      padding-right: 0;
      margin: 0 auto 15px auto;
      width: 25%;
    }
    .slick-dots {
      margin-bottom: 7px;
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .slick-dots li {
      width: 10px;
    }
    .slick-dots li button:before {
      font-size: 40px;
    }
  }

}