//
// Mobile Menu CSS
//

.burger-icon {
  float: right;
  width: 40px;
  margin: 3px 0 0 0;
  cursor: pointer;
  display: none;
  border: none;
  padding: 0;
  background: none;

  div {
    background: $blue;
    height: 6px;
    margin: 6px 0;
  }

  @media screen and (max-width: $size-XL) {
    display: block;
  }

  @media screen and (max-width: $size-M) {
    margin-top: -6px;
  }
}

@media screen and (max-width: $size-L4) {

  .smaller .burger-icon {
    margin-top: -6px;
  }

}
