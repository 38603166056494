//
// Link Block CSS
//

.link-block {

  > p {
    max-width: 1000px;
    margin: 0 auto 50px auto;
  }

  h3 {
    text-align: center;
    //margin-bottom: 50px;
  }

  .row h3 {
    font-weight: 300;
    @include font-size(30);
    text-transform: uppercase;
    color: $blue;
    margin-bottom: 15px;
  }

  a {
    display: inline-block;
    @include font-size(18);
    text-transform: uppercase;
    color: $blue;
    border: 2px solid $blue;
    padding: 6px 10px;
    margin-top: 15px;

    &:hover {
      color: $white;
      background: $blue;
    }
  }

  .col-50 {
    text-align: center;
    padding: 0 60px;
    p {
      text-align: left;
    }
  }

  .col-25 {
    text-align: center;
    padding: 0 20px;
    h2 {
      //min-height: 130px;
    }
    p {
      text-align: left;
    }
  }

  &.margin-bottom {

    .col-50 {
      margin-bottom: 50px;
    }

  }

  img {
    max-width: 160px;
  }


  @media screen and (max-width: $size-XXL) {

    .col-25 {
      padding-left: 10px;
      padding-right: 10px;
    }

  }

  @media screen and (max-width: $size-XL) {

    .col-25 {
      width: 50%;
      padding-left: 20px;
      padding-right: 20px;

      &:nth-child(3),
      &:nth-child(4) {
        padding-top: 50px;
      }
    }

  }

  @media screen and (max-width: $size-M) {

    .col-25 {
      float: none;
      width: auto;

      &:nth-child(2) {
        padding-top: 50px;
      }
    }

    .col-50 {
      margin-top: 50px;
    }

  }

}