//
// Key Visual CSS
//

.key-visual {
  //max-width: 1920px;
  margin: 0 auto 20px auto;

  img {
    max-width: none;
    width: 100%;
  }

}