//
// About us CSS
//

.about-us {

  .col-33 {
    p {
      min-height: 120px;

      @media screen and (max-width: $size-M2) {
        min-height: 50px;
      }
    }
  }

}