//
// Figures and Stats CSS
//

.figures-and-stats {

  position: relative;
  overflow: hidden;
  min-height: 500px;

  h2 {
    position: absolute;
    border: 5px solid $blue;
    border-radius: 50%;
    width: 490px;
    height: 490px;
    top: 0;
    left: -120px;
    margin: 0;
    padding: 160px 0 0 120px;
    @include font-size(60);
    line-height: 1.3;
  }

  ul {
    @include ul-reset;
    padding-top: 7px;
    max-width: 1130px;
    padding-left: 307px;

    li {
      color: $blue;
      text-transform: uppercase;
      @include font-size(24);
      margin-top: 18px;
      overflow: hidden;
      position: relative;

      span {
        display: block;
        float: right;
        background: $white;
        width: 510px;
        position: relative;
        z-index: 2;
        padding-left: 10px;
      }

      &::before {
        content: "•";
        color: $grey-mid;
        @include font-size(54);
        line-height: .7;
        background: $white;
        position: relative;
        z-index: 2;
        padding-right: 5px;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 22px;
        border-bottom: 4px dotted $grey-mid;
      }

      &:nth-child(2),
      &:nth-child(6) {
        margin-left: 50px;
      }

      &:nth-child(3),
      &:nth-child(5) {
        margin-left: 80px;
      }

      &:nth-child(4) {
        margin-left: 92px;
      }

    }
  }


  @media screen and (max-width: $size-L4) {

    h2 {
      position: relative;
      left: auto;
      top: auto;
      width: auto;
      height: auto;
      border: none;
      padding: 0;
      @include font-size(30);


      br {
        display: none;
      }

    }

    ul {
      padding: 10px 0 0 25px;

      li {
        margin-left: 0 !important;
        overflow: inherit;

        span {
          float: none;
          width: auto;
        }

        &::after {
          display: none;
        }

        &::before {
          position: absolute;
          left: -20px
        }

      }

    }

  }

}