//
// Process CSS
//


.process {
  text-align: center;
}


.process-steps {

  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: none;
    text-align: center;

    &:first-child {
      display: block;
    }

    h2 {
      @include font-size(22);
      text-transform: none;
    }

  }

}


.process-steps-bar {

  display: inline-block;
  overflow: hidden;
  padding: 20px 0;

  div {
    display: block;
    float: left;
    margin: 0 50px;
    position: relative;
    background: none;
    color: $grey-mid;
    border: 3px solid $grey-mid;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    text-align: center;
    @include font-size(56);
    padding-left: 17px;
    //line-height: 1;
    font-weight: 600;
    line-height: 110px;
    cursor: pointer;


    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 50px;
      height: 3px;
      background: $grey-mid;
      left: -53px;
      top: 58px;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 50px;
      height: 3px;
      background: $grey-mid;
      left: 117px;
      top: 58px;
    }

    &.active {
      color: $blue;
      border-color: $blue;

      &::before {
        background: $blue;
      }
      &::after {
        background: $blue;
      }

    }

    &:first-child {
      margin-left: 0;
      &::before {
        display: none;
      }
    }

    &:last-child {
      margin-right: 0;
      &::after {
        display: none;
      }
    }


  }


  @media screen and (max-width: $size-L2) {

    div {
      margin: 0 20px;
      width: 100px;
      height: 100px;
      @include font-size(48);
      padding-left: 14px;
      font-weight: 600;
      line-height: 88px;


      &::before {
        width: 20px;
        height: 3px;
        left: -23px;
        top: 47px;
      }
      &::after {
        width: 20px;
        height: 3px;
        left: 97px;
        top: 47px;
      }
    }

  }


  @media screen and (max-width: $size-S5) {

    div {
      margin: 0 6px;
      width: 60px;
      height: 60px;
      @include font-size(26);
      padding-left: 9px;
      font-weight: 600;
      line-height: 52px;


      &::before {
        width: 6px;
        height: 3px;
        left: -9px;
        top: 27px;
      }
      &::after {
        width: 6px;
        height: 3px;
        left: 57px;
        top: 27px;
      }
    }

  }

}