/*** PV Cookie Consent ***/

/* colors */
$buttonSaveSettingsBackgroundColor: #808080;
$buttonSetAllBackgroundColor: $blue;
$buttonFontColor: #FFFFFF;
$generalTextColor: #444444;


.pv-cookie-consent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, .7);
  display: flex;
  align-content: center;
  justify-content: center;
  color: $generalTextColor;

  h3 {
    margin: 0 0 25px 0;
    font-size: 18px;

    @media only screen and (min-width: 500px) {
      font-size: 24px;
    }
  }

  p {
    color: #666666;
  }
}

.pv-cookie-consent-inner {
  position: relative;
  width: 90%;
  max-height: 80vh;
  margin: auto 0;
  max-width: 650px;
  background: white;
  padding: 40px 20px 10px 20px;
  overflow-y: auto;

  @media only screen and (min-width: 500px) {
    padding: 40px 40px 10px 40px;
  }

}

.pv-cookie-settings {
  margin: 30px 0;
}

.pv-cookie-settings-options {

  label {
    display: block;
    position: relative;
    margin: 15px 0;
    padding-left: 30px;
    cursor: pointer;

    span {
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid #666666;
      width: 20px;
      height: 20px;
    }

    input {
      visibility: hidden;
    }

    input:checked + span {

      &::before {
        content: "";
        position: absolute;
        left: 3px;
        top: 3px;
        right: 3px;
        bottom: 3px;
        background-color: #999999;
      }

    }

    &:first-child {
      cursor: default;
    }
    input[disabled] + span {
      opacity: .6;
    }

  }


  @media only screen and (min-width: 650px) {
    display: flex;

    label {
      margin: 0;
    }

    label:not(:first-child) {
      margin-left: 30px;
    }
  }

}

.pv-cookie-settings-btn-group {
  margin-top: 30px;
  display: flex;
  flex-flow: column;
  align-items: center;

  button {
    border: none;
    color: $buttonFontColor;
    background: $buttonSaveSettingsBackgroundColor;
    margin-bottom: 15px;
    padding: 10px 30px;
    border-radius: 4px;
    width: 230px;
    transition: background-color 400ms ease-in-out;

    &:hover {
      background: darken($buttonSaveSettingsBackgroundColor, 10);
    }

    &.pv-cookie-consent-allow-all {
      background: $buttonSetAllBackgroundColor;

      &:hover {
        background: darken($buttonSetAllBackgroundColor, 10);
      }
    }
  }

  @media only screen and (min-width: 650px) {
    flex-flow: row;
    justify-content: space-between;

    button {
      margin-bottom: 0;
    }
  }

}


.pv-cookie-consent-details {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #999999;
}

.pv-cookie-consent-show-group-info {
  background: none;
  border: none;

  &.active {

    span {
      display: inline-block;
      transform: rotate(90deg);
    }
  }
}


.pv-cookie-consent-legal-links {
  padding: 30px 0 30px 0;
  text-align: center;

  a {
    color: $generalTextColor;
  }
}

.pv-cookie-consent-group-info-wrap {
  margin-top: 30px;
  display: none;

  &.pvcc-show-group-info {
    display: block;
  }
}

.pv-cookie-consent-group-info {
  border: 1px solid grey;
  padding: 15px 20px;
  margin-top: 15px;
}

.pv-cookie-consent-group-info-title {
  font-weight: 700;
}

.pv-cookie-consent-group-info-details {
  //display: none;
  max-height: 0;
  transition: max-height 400ms;
  overflow: hidden;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > ul > li {
    border-top: 1px solid grey;
    margin-top: 15px;
    padding-top: 15px;
  }

  a {
    color: $generalTextColor;
  }

  &.pvcc-show-cookie-details {
    //display: block;
    max-height: 1000px;
  }

}

.pv-cookie-consent-group-info-details-item {
  margin-top: 5px;

  @media only screen and (min-width: 500px) {
    display: flex;
  }
}

.pv-cookie-consent-cookie-detail-label {
  font-weight: 700;

  @media only screen and (min-width: 500px) {
    width: 120px;
    flex-shrink: 0;
    font-weight: 400;
  }
}

.pv-cookie-consent-group-info-show-cookie-details {
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  color: $generalTextColor;

  &.active {

    span {
      display: inline-block;
      transform: rotate(90deg);
    }
  }
}