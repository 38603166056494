//
// Legal Text CSS
//

.page-id-13,
.page-id-14,
.page-id-37,
.page-id-79,
.page-id-38 {

  h3 {
    margin-bottom: 15px;
    margin-top: 30px;
    @include font-size(22);
  }

  p,
  li {
    @include font-size(14);
  }
}