//
// Header CSS
//

.page > header {

  position: fixed;
  width: 100%;
  top: 0;
  background: $white;
  z-index: 9;

  transition: height 0.3s;


  @media screen and (max-width: $size-M) {
    position: relative;
  }

}

.header-top {
  background: $grey-light;
  padding: 5px 0;
  height: 35px;

  @media screen and (max-width: $size-S5) {
    height: auto;
  }
}

.header-top-inner {
  max-width: $maxPageWidth;
  margin: auto;
  overflow: hidden;
  line-height: 25px;
  color: $blue;
  @include font-size(14);

  .phone {
    float: left;
    margin-right: 15px;
    background: url('../Svgs/icon_phone_blue.svg') no-repeat;
    padding-left: 26px;
  }
  .email {
    float: left;
    background: url('../Svgs/icon_mail_blue.svg') no-repeat;
    padding-left: 26px;
  }

  @media screen and (max-width: $size-XXXL) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: $size-XXS) {
    padding-left: 15px;
    padding-right: 15px;
    @include font-size(11);
  }

  @media screen and (max-width: $size-S5) {
    .email {
      float: right;
    }
  }

}

.header-mid-inner {
  position: relative;
  max-width: $maxPageWidth;
  margin: auto;
  padding: 25px 0;
  @include clearfix();

  transition: all 0.3s;

  .logo {
    max-width: 240px;
    transition: all 0.3s;
  }

  @media screen and (max-width: $size-XXXL) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: $size-M) {

    padding-top: 15px;
    padding-bottom: 15px;

    .logo {
      max-width: 150px;
    }

  }

}


.page > header.smaller {

  box-shadow: 0 4px 4px rgba(0,0,0,.1);

  .header-mid-inner {

    padding-top: 15px;
    padding-bottom: 15px;

    .logo {
      max-width: 150px;
    }

  }

  @media screen and (max-width: $size-M) {
    box-shadow: none;
  }

}
