//
// Powermail CSS
//

.tx-powermail {
  h3 {
    //display: none;
  }
  fieldset {
    border: none;
    margin: 0 0 0 0;
    padding: 0;

    legend {
      display: none;
    }

    // show fieldset label label
    &.layout1 {
      margin-top: 40px;

      legend {
        display: block;
        margin-bottom: 5px;
        background: $blue;
        width: 100%;
        color: white;
        padding: 10px;
      }

    }

    &:first-of-type {
      &.layout1 {
        margin-top: 10px;
      }
    }

  }
  label {
    display: block;
  }
  input {
    border: none;
    width: 100%;
    padding: 10px;
    //border-radius: 5px;
    background: $grey-light;
    line-height: 25px;
  }

  select {
    width: 100%;
    background: $grey-light;
    padding: 10px;
    border: none;

  }

  textarea {
    border: none;
    width: 100%;
    padding: 10px;
    //border-radius: 5px;
    background: $grey-light;
  }

  .powermail_fieldwrap {
    margin-top: 15px;
  }

  .powermail_input {
  }

  .powermail_fieldwrap_type_check {
    overflow: hidden;

    .checkbox {
      position: relative;
      padding-left: 28px;

      input {
        width: auto;
        position: absolute;
        left: 2px;
        top: 6px;
      }
    }
  }

  .powermail_checkbox {

  }

  .powermail_fieldwrap_type_submit {
    margin-top: 30px;
  }

  .powermail_fieldwrap_submit {
    //padding-top: 15px;
  }

  .powermail_submit {
    background: $blue;
    color: $white;
    cursor: pointer;
    //height: 24px;
    width: auto;
    padding: 5px 10px;
  }

  ul {
    margin: 0;
  }

  .parsley-required,
  .parsley-custom-error-message {
    @include font-size(10);
  }

    // Job Application

  @media screen and (min-width: $size-M2) {

    fieldset {
      margin-top: 0;
    }

    form.layout3 {

      > fieldset {

        &:nth-of-type(1) {
          float: left;
          width: 50%;
          padding-right: 30px;
        }

        &:nth-of-type(2) {
          float: left;
          width: 50%;
          padding-left: 30px;
        }

        &:nth-of-type(3) {
          clear: both;
          padding-top: 20px;
        }

      }

    }

  }


  // dynamic fields add
  .form-field-dynamic-add-on {

    .powermail_field {
      position: relative;
    }

    .add-item {
      position: absolute;
      right: 10px;
      top: 12px;
      background: #dedede;
      border: 1px solid $grey-dark;

      &:hover {
        background: $grey-light;
      }
    }

  }

}