//
// Project Color Definitions
//

$blue: #0a3f8c;
$grey: #9d9d9c;
$grey-light: #f5f5f5;
$grey-light2: #f7f7f7;
$grey-mid: #e5e5e5;
$grey-dark: #3c3c3b;
$white: #FFFFFF;
$black: #000000;