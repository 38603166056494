//
// Marketing CSS
//

.marketing {

  .key-visual {
    margin-bottom: 0;

    img {
      display: block;
    }
  }

  h1 {
    text-align: left;
    line-height: 1.15;
  }

  h2 {
    margin-top: 40px;
    text-transform: none;
    text-align: left;
    hyphens: auto;
    line-height: 1.2;
    @include font-size(26);
  }

  h3 {
    @include font-size(30);
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 1.2em;
    hyphens: auto;
    line-height: 1.15;
  }

  .ce-bodytext {
    h2:first-of-type {
      margin-bottom: 40px;
    }
  }


  main {
    padding-top: 0;

    p {
      margin-bottom: 1.2em;
    }

    > section {
      margin: 0;
      padding: 50px 0;
      max-width: none;

      &:nth-child(odd) {
        background: $grey-light;
      }

    }

    .ce-inner {
      margin: auto;
      max-width: $maxPageWidth;
      padding-left: 0;
      padding-right: 0;

      @media screen and (max-width: $size-XXXL) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

  }


  .intro-text {

    header {
      margin-bottom: 40px;
    }

  }

  p {
    hyphens: auto;
  }


  .ce-marketing {

    header {
      background: url("../Svgs/Erfahrung.svg") no-repeat left center;
      background-size: 120px 120px;
      min-height: 160px;
      padding-left: 150px;
      margin-bottom: 40px;
      display: flex;
    }

    h3 {
      margin: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &.ce-id-51 header {
      background-image: url("../Svgs/Partnerschaft.svg");
    }
    &.ce-id-52 header {
      background-image: url("../Svgs/Ansprechpartner.svg");
    }
    &.ce-id-53 header {
      background-image: url("../Svgs/Professionalitaet.svg");
    }
    &.ce-id-54 header {
      background-image: url("../Svgs/Eigentuemer.svg");
    }
    &.ce-id-55 header {
      background-image: url("../Svgs/Feedback.svg");
    }


    @media screen and (max-width: $size-S) {

      header {
        background-position: center top;
        padding-left: 0;
        padding-top: 140px;
      }
    }

  }


  .ce-marketing-pros-and-nogoes {
    margin-bottom: 40px;

    overflow: hidden;

    ul {
      @include ul-reset;
      overflow: hidden;
      margin-left: -30px;

      li {
        display: inline-block;
        background: url("../Svgs/icon_check.svg") no-repeat 0 6px;
        background-size: 20px 20px;
        color: $blue;
        padding: 5px 0 5px 34px;
        margin-left: 30px;
        white-space: nowrap;
      }

      &:nth-child(2) {
        display: none;
        li {
          background: url("../Svgs/icon_cross.svg") no-repeat 0 6px;
          background-size: 20px 20px;
        }
      }

    }

  }


  .ce-marketing-full-text {
    padding: 20px 0 20px 0;
    //display: none;
    column-gap: 40px;
    column-count: 2;
    //max-height: 0;
    //overflow: hidden;
    //transition: max-height 0.3s ease-in-out;


    @media screen and (max-width: $size-M) {
      column-count: auto;
    }

  }

  .ce-marketing.active {
    .ce-marketing-full-text {
      //display: block;
      //max-height: 1200px;
    }
  }


  .ce-marketing-btn {
    border: none;
    background: none;
    color: $blue;
    text-transform: uppercase;
    text-align: center;
    width: 150px;
    margin: auto;
    display: block;
    position: relative;
    padding-bottom: 26px;

    &.open {

      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 0;
        width: 0;
        bottom: 0;
        left: 50%;
        border-top: 18px solid $blue;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        transform: translateX(-50%);
        z-index: 3;
      }

    }

    &.close {
      padding: 26px 0 0 0;
      display: none;

      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 0;
        width: 0;
        top: 0;
        left: 50%;
        border-bottom: 18px solid $blue;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        transform: translateX(-50%);
        z-index: 3;
      }

    }

  }

  .ce-marketing.active {
    .ce-marketing-btn {
      // always temp hide
      display: none !important;
      &.close {
        //display: block;
      }
      &.open {
        //display: none;
      }
    }
  }

  .ce-marketing-btn {
    // always temp hide
    display: none !important;
  }

}