//
// Imprint CSS
//

.page-id-13 .content-main {

  > div {

    &:nth-of-type(2) {
      margin-bottom: 30px;

      @media screen and (min-width: $size-S4) {
        float: left;
        width: 50%;
        margin-bottom: 15px;
      }
    }

  }

}