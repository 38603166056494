//
// FAQ CSS
//

.faq {

  main {
    display: flex;
    margin: auto;
    max-width: 1200px;
  }

  .nav-categories {
    flex-shrink: 0;
    width: 320px;

    ul {
      @include ul-reset;

      // regular menu
      li {
        background: $blue;
        border-top: 1px solid lighten($blue, 5);

        &:first-child {
          border-top: none;
        }

        a {
          display: block;
          padding: 10px 12px;
          color: $white;

          &:hover,
          &.active {
            background: lighten($blue, 10);
          }
        }
      }

      // rounded version
      /*
      li {
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }

        a {
          display: block;
          padding: 10px 12px;
          color: $white;
          border-radius: 12px;
          background: $blue;

          &:hover,
          &.active {
            background: lighten($blue, 10);
          }
        }

      }
      */

    }


  }

  .content-main {
    max-width: none;
    margin: 0;
    width: 100%;
    padding-left: 40px;
  }


  h1 {
    text-align: left;
    margin-top: 0;
    margin-bottom: 20px;
  }


  .ce-faq {

    border-top: 1px solid $blue;

    &:last-child {
      border-bottom: 1px solid $blue;
    }

    h3 {
      position: relative;
      text-transform: none;
      @include font-size(20);
      background: $grey-light;
      margin: 0;
      padding: 8px 10px;
      cursor: pointer;

      &::before {
        position: absolute;
        content: "";
        right: 11px;
        top: 20px;
        width: 20px;
        height: 2px;
        background: $blue;
        z-index: 2;
      }

      &::after {
        position: absolute;
        content: "";
        right: 20px;
        top: 11px;
        height: 20px;
        width: 2px;
        background: $blue;
        z-index: 2;
        transition: 100ms ease-in-out;

      }
    }

    .faq-answer {
      border-top: 1px solid #d4d4d4;
      padding: 20px 10px;
      display: none;
    }

    &.open {

      h3 {
        &::after {
          transform: rotate(90deg);
        }
      }

      .faq-answer {
        //display: block;
      }
    }


    .faq-images {
      margin-top: 20px;
      display: flex;
    }

    .faq-image {
      width: calc(100% / 3);
      padding: 10px;

      figcaption {
        @include font-size(14);
        padding: 0 5px;
      }
    }

  }

}