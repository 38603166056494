//
// Call To Action CSS
//

.call-to-action {

  height: 265px;
  background: url("../Images/bg_action_area2.jpg") no-repeat top center;
  background-size: 100%;
  text-align: center;
  color: $white;
  margin: 80px 0 0 0;
  padding-top: 30px;

  h3 {
    @include font-size(30);
    text-transform: none;
    margin-bottom: 5px;
    color: $white;
  }

  p {
    @include font-size(18);
    margin-bottom: 30px;
  }

  a {
    display: inline-block;
    @include font-size(18);
    text-transform: uppercase;
    color: $white;
    border: 2px solid $white;
    padding: 6px 20px;

    &:hover {
      color: $blue;
      background: $white;
    }

    &.btn-inverse {
      background: $white;
      color: $blue;

      &:hover {
        background: transparent;
        color: $white;
      }
    }
  }


  @media screen and (max-width: $size-L) {
    height: auto;
    padding-top: 5px;
    padding-bottom: 30px;
  }


  @media screen and (max-width: $size-S4) {
    .btn-group {
      a {
        margin-bottom: 15px;
      }
    }
  }

}