//
// Defaul Project Settings
//

$maxPageWidth: 1200px;
$baseFont: 'Open Sans', sans-serif;

//breakpoints
$size-XXXXL: 1520px;
$size-XXXL: 1460px;
$size-XXL: 1300px;
$size-XL: 1200px;
$size-L4: 1050px;
$size-L2: 860px;
$size-L: 800px;
$size-M2: 750px;
$size-M: 700px;
$size-S5: 600px;
$size-S4: 530px;
$size-S3: 480px;
$size-S: 430px;
$size-XS: 400px;
$size-XXS: 380px;
$size-XXXS: 360px;