//
// Nav Footer CSS
//

.nav-footer {

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;

    li {
      float: left;
      margin-left: 15px;

      a {
        color: $white;
      }
    }
  }

  @media screen and (max-width: $size-L4) {
    float: right;
  }

  @media screen and (max-width: $size-S4) {
    float: none;

    ul {
      text-align: center;

      li {
        display: inline-block;
        float: none;
      }
    }
  }

}