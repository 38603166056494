//
// Contact CSS
//

.contact {

  h1 {
    text-align: left;
  }

  .sidebar {
    float: right;
    width: 230px;
    margin: 100px 0 0 70px;

    .btn-primary {
      @include font-size(16);
    }


    @media screen and (max-width: $size-S5) {
      float: none;
      width: auto;
      margin: 0 0 30px 0;
    }
  }

  .powermail_fieldwrap_type_submit .btn-primary {
    padding-top: 2px;
    padding-bottom: 3px;
    margin-left: 8px;
  }

}