//
// Stats CSS
//

.stats {

  //background: $grey-light2;
  background: url("../Images/bg_action_area2.jpg") no-repeat top center;
  background-size: 100%;
  text-align: center;
  color: $white;
  padding: 20px 0;

  .row {
    max-width: $maxPageWidth;
    margin: auto;
    display: flex;
  }

  .stats-value {
    @include font-size(72);
  }

  .stats-label {
    @include font-size(18);
  }

  @media screen and (max-width: $size-L4) {
    .stats-value {
      @include font-size(36);
    }

    .stats-label {
      @include font-size(18);
    }
  }

  @media screen and (max-width: $size-M2) {
    .col-20 {
      float: none;
      width: calc(100% / 4);

      &:nth-child(5) {
        display: none;
      }
    }
  }


  @media screen and (max-width: $size-S5) {
    .col-20 {
      width: calc(100% / 3);

      &:nth-child(4) {
        display: none;
      }
    }
  }

  @media screen and (max-width: $size-S3) {
    .col-20 {
      width: calc(100% / 2);

      &:nth-child(3) {
        display: none;
      }
    }
  }

}

.page-id-2,
.page-id-3 {
  .stats {
    margin-bottom: 0;
  }
}