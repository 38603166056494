//
// Footer CSS
//

body > footer {

  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 55px;
  width: 100%;
  background: $grey;

  @media screen and (max-width: $size-L4) {
    height: auto;
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
  }

}

.footer-inner {
  max-width: $maxPageWidth;
  margin: auto;
  overflow: hidden;
  padding: 10px 0;
  line-height: 35px;
  color: $white;

  @media screen and (max-width: $size-XXXL) {
    padding-left: 20px;
    padding-right: 20px;
  }

}

.copyright {
  float: left;

  @media screen and (max-width: $size-S4) {
    float: none;
    text-align: center;
  }
}