//
// Import Base CSS and add custom
//

// Base Settings
@import "base/settings";
@import "base/colors";
@import "base/fonts";

// Mixins
@import "base/mixins";

// Normalize
@import "base/normalize";

// Add global CSS
@import "base/global";

// Add Magnific Popup
@import "lib/magnific_popup/main";

@import "lib/slick_slider/base";


//
// CUSTOM CSS
//

// general

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: $baseFont;
  @include font-size(16);
  background: $white;
  color: $grey-dark;
  overflow-x: hidden;
}

.page {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  //padding-top: 148px;
  padding-top: 139px;
  padding-bottom: 55px;

  @media screen and (max-width: $size-M) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

h1 {
  font-weight: 300;
  @include font-size(30);
  text-transform: uppercase;
  text-align: center;
  color: $blue;
  margin-bottom: 1.2em;
}

h2 {
  font-weight: 300;
  @include font-size(30);
  text-transform: uppercase;
  color: $blue;
  margin-bottom: 15px;
}

h3 {
  @include font-size(26);
  color: $blue;
  font-weight: 400;
  text-transform: uppercase;
}

p {
  margin: 0 0 1em 0;
  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: $blue;
  text-decoration: none;
}

.text-blue {
  color: #0a3f8c;
}


@import "grid";

@import "header";
@import "burger-icon";
@import "key_visual";
@import "social-media";
@import "nav-main";

@import "main";

@import "footer";
@import "nav-footer";

@import "link-block";
@import "call-to-action";
@import "stats";
@import "button-grid";
@import "process";
@import "contact";
@import "figures-and-stats";
@import "about-us";
@import "career-at-abalon";
@import "testimonials-slider";
@import "references";
@import "ce-video";

@import "open-jobs";
@import "powermail";

@import "blog";
@import "marketing";
@import "legal-text";
@import "imprint";
//@import "cookie-consent";
@import "faq";
@import "recommendation";
@import "pv_cookie_consent";
//@import "campaign";