//
// Recommendation CSS
//

#c222 {
  margin-bottom: 40px;

  @media screen and (min-width: $size-M2) {

    h1 {
      text-align: left;
    }

    p {
      display: none;
    }
  }

}